import * as React from "react"
import styled from "styled-components"
import { css } from "styled-components"

import Caret from "../images/caret.svg"
import Ext from "../images/ext.svg"
import { Link } from "gatsby"

const sharedStyle = css`
  font-size: 1rem;
  line-height: 1.5;
  background-image: linear-gradient(98deg, #e5eff6, #e5eff6 32px, #fff 33px);
  padding: 8px 15px 8px 40px;
  border: 1px solid #e5eff6;
  font-weight: 400;
  position: relative;
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    background-image: linear-gradient(90deg, #fff, #e5eff6);

    svg {
      left: 12px;
    }
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.3s linear;
    opacity: 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }

  svg {
    transition-duration: 0.3s;
    transition-property: left;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 7px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`

const StyledButton = styled(Link)`
  ${sharedStyle}
`

const LinkBtn = ({ children, target, to, ...props }) => {
  return target === "_blank" ? (
    <StyledButton {...props} target={target} href={to}>
      <Ext></Ext>
      {children}
    </StyledButton>
  ) : (
    <StyledButton {...props} target={target} to={to}>
      <Caret></Caret>
      {children}
    </StyledButton>
  )
}

export default LinkBtn
