import * as React from "react"

import styled from "styled-components"

import StyledImageWrapper from "./styled_image_wrapper"

import { Grid, Image } from "grommet"

const StyledImage = styled(Image)`
  position: absolute;
  object-fit: cover;
  object-position: top center;
`

const ContentWrapper = styled.div`
  padding: 2rem;
`

const ImageWithOverlay = ({ children, backgroundSrc }) => {

  return (
    <Grid>
      <StyledImageWrapper style={{minHeight:"560px"}}>
        <StyledImage fill src={backgroundSrc} />
        <ContentWrapper>{children}</ContentWrapper>
      </StyledImageWrapper>
    </Grid>
  )
}

export default ImageWithOverlay
