import styled from "styled-components"
import { Box, Heading } from "grommet"

export const KrohneHeading = styled(Heading)`
  font-weight: bold;
  color: #005da8;
`
export const AngledBox = styled(Box)`
  position: relative;
  &:before {
    content: "";
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    top: 0;
    clip-path: polygon(100% 100%, 0 0%, 50% 0, 100% 0);
    background: var(--white);
  }
`
