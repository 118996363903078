import * as React from "react"

import styled from "styled-components"

import {
  Box,
  Heading,
  Paragraph,
  Image,
  ResponsiveContext,
  Grid,
} from "grommet"

import StyledImageWrapper from "../components/styled_image_wrapper"
import StyledImage from "../components/styled_image"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ImageWithOverlay from "../components/image_with_overlay"
import Button from "../components/linkbutton"
import { Succeed } from "../components/succeed"
import Container from "../components/container"

const CustomHeading = styled(Heading)`
  color: var(--blue);
  font-weight: bold;
`

const StyledHeader = styled(Heading)`
  position: relative;
`

const StyledIcon = styled(Image)`
    margin-left: -1.25em;
    height: 0.75em;
`

const CIIPage = () => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Layout>
      <Seo title="CII Rating" />
      <ImageWithOverlay backgroundSrc="/CII_Slider.png">
        <Container pad="large">
          <StyledHeader>
            <Box direction="row" gap="small" align="baseline">
              <StyledIcon src="/favicon-32x32.png" />
              <Heading>
                Get ready to meet CII, a new sustainable regulation, via EcoMATE™
              </Heading>
            </Box>
          </StyledHeader>
        </Container>
      </ImageWithOverlay>
      <Container pad="large">
        <Box direction="row-responsive" gap="large">
          <Box basis="3/4">
            <CustomHeading level="2">
              EcoMATE™ is now ready <br />
              with a Carbon Intensity Indicator (CII)
            </CustomHeading>
            <Paragraph>
              Integrated into the dashboard, allowing ship operators and
              managers to readily quantify, comply and report on carbon
              emissions from ongoing operations.
            </Paragraph>
            <Button style={{ width: "260px" }} to="https://krohne-marine.com/" target="_blank">
              Get in touch with us today
            </Button>
          </Box>
          <Box basis="1/2">
            <StyledImageWrapper pageSize={size}>
              <StyledImage
                style={{ marginTop: "55px", width: "70%" }}
                src="/cii_pictures.png"
              />
            </StyledImageWrapper>
          </Box>
        </Box>
      </Container>
      <hr />
      <Container pad="large">
        <Box direction="row-responsive" gap="large">
          <Box basis="3/4">
            <Heading level="4" style={{ fontWeight: "bold" }}>
              A significant but necessary challenge for the marine industry
            </Heading>
            <Paragraph>
              To meet global shipping decarbonisation targets, ship managers and
              owners with ships in service (greater than 5,000 GT) must prepare
              in advance for the CII. Based on reported IMO DCS data, a ship's
              performance will be graded annually on a fivetiered scale (A to
              E).
            </Paragraph>
            <Paragraph>
              Ship managers are then required to consider the CII profiles of
              their vessels on an annual basis in order to reduce carbon
              emissions and comply with regulations. Furthermore, by the end of
              2022, vessels receiving an E or D rating (three years in a row)
              must have an optimised Ship Energy Efficiency Management Plan
              (SEEMP) developed and approved.
            </Paragraph>
            <Paragraph>
              The SEEMP must include a plan for achieving CII targets and
              documentation of a ship's compliance to CII requirements. From
              that point forward CII requirements must be present onboard and
              will become more stringent over time.
            </Paragraph>
          </Box>
          <Box basis="1/2">
            <Heading level="4" style={{ fontWeight: "bold" }}>
              The EEXI and CII
            </Heading>
            <Paragraph marginTop="-20px">
              is a part of IMO's short term climate targets
            </Paragraph>
            <Grid
              style={{ marginBottom: "10px" }}
              fill
              areas={[
                { name: "image", start: [0, 0], end: [0, 0] },
                { name: "text", start: [1, 0], end: [1, 0] },
              ]}
              columns={["xsmall", "flex"]}
              rows={["flex"]}
              gap="large"
            >
              <Box gridArea="image">
                <StyledImageWrapper pageSize={size}>
                  <StyledImage src="/dart.png" />
                </StyledImageWrapper>
              </Box>
              <Box gridArea="text">
                <Paragraph>
                  <b>By 2023, all vessels above 5 000 GT </b>
                  <br />
                  and trading internationally
                  <br />
                  <b> must fulfill new sustainable regulations</b>
                </Paragraph>
              </Box>
            </Grid>
            <Grid
              fill
              areas={[
                { name: "image", start: [0, 0], end: [0, 0] },
                { name: "text", start: [1, 0], end: [1, 0] },
              ]}
              columns={["xsmall", "flex"]}
              rows={["flex"]}
              gap="large"
            >
              <Box gridArea="image">
                <StyledImageWrapper pageSize={size}>
                  <StyledImage src="/globe.png" />
                </StyledImageWrapper>
              </Box>
              <Box gridArea="text">
                <Paragraph>
                  Goal of{" "}
                  <b>
                    40% reduction of CO<sub>2</sub>
                  </b>{" "}
                  ship
                  <br /> emissions <b>by 2030</b>
                </Paragraph>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Succeed>
        <Heading level="3" style={{ fontWeight: "bold" }}>
          The solution for compliancy and combating climate change
        </Heading>
        <Paragraph>
          Shipowners, operators, managers, and crew members must act to
          reduce emissions and prepare for compliance in a timely and
          cost-effective manner. There are numerous ways for a ship to
          improve its energy efficiency and reduce its carbon footprint.
          Energy-efficient instruments, systems, and technologies are
          required to qualify for higher ratings.
        </Paragraph>
        <Paragraph>
          Proactively improve CII rankings, fleet performance, bunkering,
          and reporting while lowering costs with EcoMATE™, a complete
          vessel performance monitoring system and fleet operation
          optimisation software. A combination of our instruments, such as
          OPTIMASS Coriolis mass flowmeters, and the EcoMATE™ system is
          the ideal tool for achieving the highest ratings and
          implementing improvement plans.
        </Paragraph>
        <Paragraph>
          Achieve the ability to combine profitability and performance by
          utilising our comprehensive system and instruments. Gain a
          positive change for your company and for the environment.
        </Paragraph>
      </Succeed>
    </Layout>
  )
}

export default CIIPage
