import * as React from "react"

import { Box, Paragraph } from "grommet"

import StyledImageWrapper from "../components/styled_image_wrapper"
import StyledImage from "../components/styled_image"
import Button from "../components/linkbutton"
import { KrohneHeading, AngledBox } from "../styles/styles"
import Container from "./container"

export const Succeed = ({children}) => {

  return (
    <>
      <AngledBox background="#F7F8F9">
          <Container gap="large">
            <Box direction="row-responsive" gap="large" align="center">
              <Box basis="1/2" pad={{vertical: "large"}}>
                <KrohneHeading>EcoMATE™</KrohneHeading>
                <Paragraph>
                  Complete Vessel Performance Monitoring System with Fleet
                  Operation Optimisation Software
                </Paragraph>
                <StyledImage src="/ecomate_cloud_multiple_dashboards.png" />
              </Box>
              <Box basis="1/2" pad={{vertical: "large"}}>
                {children}
              </Box>
            </Box>
          </Container>
      </AngledBox>
      <Container pad="large">
        <Box direction="row-responsive" gap="medium" align="center">
          <Box basis="1/2">
            <KrohneHeading>We can help you succeed</KrohneHeading>
            <Paragraph>
              KROHNE Marine is ready to support you with our 60+ years of marine
              expertise through complete turn-key solutions and comprehensive
              services.
            </Paragraph>
            <Paragraph>
              With our own R&D, engineering & system design, production, service &
              spare parts, sales, and marketing team, we take over responsibility
              for the whole project lifecycle. From first consultancy to
              commissioning - our full service concept guarantees smooth and
              continuous operation on board.
            </Paragraph>
            <Paragraph>
              KROHNE is one single interface, from the instrument to the data
              available ashore
            </Paragraph>
            <Button style={{ width: "200px" }} to="https://krohne-marine.com/" target="_blank">
              Learn more
            </Button>
          </Box>
          <Box basis="1/2" align="center">
            <StyledImageWrapper>
              <StyledImage
                style={{ marginBottom: "10px" }}
                src="/measuring_the_facts.png"
              />
              <br />
              <StyledImage
                style={{ marginBottom: "10px" }}
                src="/no_of_installations.png"
              />
              <br />
              <StyledImage src="/approved_by_verifiers.png" />
            </StyledImageWrapper>
          </Box>
        </Box>
      </Container>
    </>
  )
}
