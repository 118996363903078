import styled from "styled-components"

const imageSize = {
  xsmall: "50vh",
  small: "50vh",
  medium: "80vh",
  large: "80vh",
  xlarge: "80vh",
  xxlarge: "80vh",
}

const StyledImageWrapper = styled.div`
  position: relative;
  height: ${props => imageSize[props.pageSize]};
`

export default StyledImageWrapper
