import styled from "styled-components"

import { Image } from "grommet"

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: top center;
  max-width: 100%;
  max-height: 100%;
`

export default StyledImage
